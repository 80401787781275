exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accommodation-tsx": () => import("./../../../src/pages/accommodation.tsx" /* webpackChunkName: "component---src-pages-accommodation-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-explore-tsx": () => import("./../../../src/pages/explore.tsx" /* webpackChunkName: "component---src-pages-explore-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-travel-tsx": () => import("./../../../src/pages/travel.tsx" /* webpackChunkName: "component---src-pages-travel-tsx" */)
}

